.no-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  background: linear-gradient(135deg, #f0f2f5, #ffffff);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: auto !important;
}

.no-stats-container .ant-empty {
  margin-bottom: 20px;
}

.no-stats-container .ant-btn-primary {
  margin-top: 16px;
  border-radius: 5px;
}


/* Styles de base pour la structure */
.row {
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 95%;
}

.col-left,
.col-right {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Styles pour les petits écrans (téléphones) */
@media (max-width: 480px) {
  .row {
    width: 100%;
    flex-direction: column;
  }

  .col-left,
  .col-right {
    width: 100%;
    padding: 10px 0;
  }
}

/* Pour les téléphones en mode paysage ou petits écrans moyens */
@media (min-width: 481px) and (max-width: 767px) {
  .row {
    width: 100%;
    flex-direction: column;
  }

  .col-left,
  .col-right {
    width: 100%;
    padding: 10px 0;
  }
}

/* Pour les écrans de 1024px de largeur ou moins (téléphones et petites tablettes) */
@media (max-width: 1024px) {
  .row {
    width: 100%;
    flex-direction: column;
  }

  .col-left,
  .col-right {
    width: 100%;
    padding: 10px 0;
  }
}

/* Pour les tablettes en paysage et petits écrans d'ordinateur (de 1025px à 1366px) */
@media (min-width: 1025px) and (max-width: 1366px) {
  .row {
    width: 95%;
    flex-direction: row;
  }

  .col-left {
    flex: 3;
    max-width: 60%;
  }

  .col-right {
    flex: 2;
    max-width: 40%;
  }
}

/* Pour les grands écrans (résolutions HD et supérieures) */
@media (min-width: 1367px) {
  .row {
    width: 100%;
    flex-direction: row;
  }

  .col-left {
    flex: 3;
    max-width: 60%;
  }

  .col-right {
    flex: 2;
    max-width: 40%;
  }
}
