body {
    --color-title: #08418b;
    --color-icon: #08418b;
    --color-background: #f4f6f9;
    --color-text: #242038;

}

.tools{
    cursor: pointer;
}

.card.minimized {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }



.arborescence h2 {
    font-size: 2em;
    color: var(--color-title);
    padding-left: 15px;
}


.arborescence .anticon,
.arborescence .icon {
    cursor: pointer;
    margin-left: 5px;
    color: var(--color-icon) !important;
    transition: color 0.3s ease;
}

.arborescence .anticon:hover {
    color: #2a86ff !important;
}

.arborescence .CancelIcon:hover {
    color: #e74c3c !important;
}


/* Animation du soulignement pour le nom éditable dans l'arborescence */
.FolderTree .EditableName .displayName {
    position: relative;
    color: var(--color-text);
    font-weight: 600;
}

.FolderTree .EditableName .displayName::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    /* Ajustez la position du soulignement */
    width: 0;
    height: 2px;
    /* Épaisseur du soulignement */
    background-color: #2a86ff;
    /* Couleur du soulignement au repos */
    transition: width 0.3s ease;
}

.FolderTree .EditableName .displayName:hover::before {
    width: 100%;
    /* Largeur complète du soulignement au survol */
}

.FolderTree .EditableName .displayName:hover {
    color: #2a86ff !important;
}


/* Card MAC */
.card {
    margin: 0 auto;
    background-color: var(--color-background);
    border-radius: 8px;
    z-index: 1;
    padding-bottom: 20px !important;
}

.tools {
    display: flex;
    align-items: center;
    padding: 9px;
}

.circle {
    padding: 0 4px;
}

.box {
    display: inline-block;
    align-items: center;
    width: 10px;
    height: 10px;
    padding: 1px;
    border-radius: 50%;
}

.red {
    background-color: #ff605c;
}

.yellow {
    background-color: #ffbd44;
}

.green {
    background-color: #00ca4e;
}