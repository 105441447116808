/* Style général du formulaire de connexion */
.login-form {
    width: 100%;
    margin: auto;
}

/* Style pour les icônes dans les champs de saisie */
.site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
}

/* Boutons et messages */
.login-form-button {
    width: 100%;
    font-size: 16px;
    padding: 10px;
}

.login-form-forgot {
    float: right;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

h6 {
    text-align: center;
}

/* Ajustements pour petits écrans (mobiles) */
@media screen and (max-width: 480px) {
    .login-form {
        padding: 15px;
    }

    .login-form-button {
        font-size: 14px;
    }

    .login-form-forgot {
        font-size: 12px;
    }

    h6 {
        font-size: 14px;
    }

    .login-form .ant-input,
    .ant-input-affix-wrapper,
    .ant-form-item {
        font-size: 14px;
    }

    .error-message {
        font-size: 12px;
    }
}

/* Ajustements pour grands écrans (tablettes et ordinateurs de bureau) */
@media screen and (min-width: 768px) {
    .login-form {
        max-width: 500px;
    }

    .login-form-button {
        font-size: 16px;
    }

    .login-form-forgot {
        font-size: 14px;
    }

    h6 {
        margin: 0 0 10px 0;
        font-size: 18px;
    }
}

/* Ajustements pour très grands écrans (moniteurs 4K) */
@media screen and (min-width: 1920px) {
    .login-form {
        max-width: 600px;
    }

    .login-form-button {
        font-size: 18px;
    }

    .login-form-forgot {
        font-size: 16px;
    }

    h6 {
        font-size: 20px;
        margin: 0 0 15px 0;
    }

    .login-form .ant-input,
    .ant-input-affix-wrapper,
    .ant-form-item {
        font-size: 18px;
    }

    .error-message {
        font-size: 16px;
    }
}
