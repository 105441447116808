

.AjoutAddEditContrat .ant-steps{
    padding : 15px !important
}


.AjoutAddEditContrat .ant-btn{
    margin : 5px !important
}

.AjoutAddEditContrat{
    width : 95% !important ;
}

@media  (min-width: 1400px) {
    .AjoutAddEditContrat .ant-form-item{
    margin :auto !important;
    margin-bottom : 20px !important;
    width : 60%;
}  
.AjoutAddEditContrat{
    width : 50% !important ;
}
}

.AjoutAddEditContrat .ant-form-item {
    margin: auto !important;
    margin-bottom: 20px !important;
}

.contrat-form-group {
  display: flex;
  flex-wrap: wrap;
}

.contrat-form-group > .ant-form-item {
  flex: 1;
  margin-right: 30px !important; /* Ajustez l'espacement entre les champs si nécessaire */
}


@media  (max-width: 800px) {
    .contrat-form-group > .deleteConges {
        margin-top: -20px !important; 
  }

}



    
