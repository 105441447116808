.file-explorer .fe-table{
    overflow: hidden;
}

.fe-table, .icons-view-grid {
    --table-max-height: 80vh;
    max-height: var(--table-max-height);
    overflow: auto;
}

/* Header de l'explorateur de fichiers */
.file-explorer-header {
    background-color: var(--background-color, #c7c7c7);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;

    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: auto 5fr auto;
    align-items: center;
    gap: 10px;
}

/* Bouton retour */
.back-button {
    grid-row: 1;
    grid-column: 1;

    display: flex;
    gap: 10px;
}

.mobile{
    margin: 75px 0 0 0;
}

/* Chemin de navigation */
.path-history {
    grid-row: 1;
    grid-column: 2;

    font-size: 14px;
    color: #6b6b6b;
    border: 1px solid #e0e0e0;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
    text-align: center;
}

.feh-parent .path-history {
    grid-row: 1;
    grid-column: 2/4
}

.path-history ol {
    justify-content: center !important;
    flex-wrap: nowrap !important;
}

.path-history .ant-breadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
}

.path-history .ant-breadcrumb-item {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.file-explorer-header .search-input {
    grid-row: 2;
    grid-column: 1/4;

    display: grid;
    grid-template-columns: auto 5fr;
    gap: 10px
}

.display-choise-fe {
    grid-row: 2;
    grid-column: 1;

    margin: auto !important;
}

.path-history span {
    white-space: nowrap;
}

/* Action buttons (ajouter dossier et fichier) */
.action-buttons {
    grid-row: 1;
    grid-column: 3;

    display: flex;
    gap: 10px;
}

.container-action-file-explorer {
    display: flex;
    justify-content: center;
    gap: 2vw;
    flex-wrap: nowrap !important;
}

.container-action-file-explorer *, .file-explorer .ant-table-cell{
   font-size: 1.3em;
}

.file-explorer .ant-table-cell-row-hover {
    background-color: var(--hover-color, rgba(0, 64, 142, 0.3)) !important;
    color: var(--hover-text-color, #000) !important;
    font-weight: bold;
    cursor: pointer;
}

.action-column{
    cursor: default !important;
}

.icons-view-grid .icon-item:hover {
    background-color: var(--hover-color, rgba(0, 64, 142, 0.3)) !important;
    color: var(--hover-text-color, #000) !important;
    font-weight: bold;
    border-radius: 8px;
}

.btn-add-folder, .btn-add-file{
    border: none !important;
    background-color: #fff !important;
}

.btn-add-folder:hover, .btn-add-file:hover {
    background-color: #eee !important;
}

.file-explorer tr:nth-child(even) {
    background-color: #e9e9e9;
}

.file-explorer .ant-table-thead > tr > th {
    background-color: #c7c7c7;
    font-weight: bold;
}

.container-fe-icone {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    border-radius: 8px;
}

.icon-filters {
    margin: 5px auto;
}

.icons-view-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
}

.icon-label {
    margin-top: 8px;
    font-size: 14px;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 80px;
}

.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 10px 15px;
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    transition: background-color 0.2s;
    margin: 0 0 10px 0 !important;
}

.icon-actions {
    display: flex;
    gap: 8px;
    margin-top: 5px;
    justify-content: center;
}

/* Supprime la bordure droite pour le dernier élément de chaque ligne */
.icon-item.last-in-row {
    border-right: none;
    margin-right: 0;
}

.no-items-message {
    background-color: #fafafa;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    font-size: 1.2em;
    color: #666;
    margin-top: 20px;
}

/* Smartphones (max-width: 600px) */
@media (max-width: 600px) {
    .file-explorer {
        padding: 10px 0;
    }

    .file-explorer-header {
        grid-template-columns: auto 3fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 10px;
    }

    .feh-parent {
        grid-template-columns: auto 3fr !important;
        grid-template-rows: repeat(2, 1fr) !important;
        gap: 10px !important;
    }

    .back-button {
        grid-row: 1;
        grid-column: 1;

        align-self: flex-start;
        margin: 0;
    }

    .back-button * {
        font-size: 0.95em;
    }

    .path-history {
        grid-row: 1;
        grid-column: 2;

        text-align: left;
        padding: 2px 5px;
    }

    .feh-parent .path-history {
        grid-column: 2/3
    }

    .path-history ol {
        justify-content: left !important;
    }

    .action-buttons {
        grid-row: 2;
        grid-column: 1/3;

        justify-content: center;
    }

    .action-buttons .ant-btn, .back-button, .path-history span  {
        font-size: 12px;
    }

    .container-action-file-explorer *, .file-explorer .ant-table-cell{
        font-size: 1em;
    }

    .container-action-file-explorer .moveItemIcon {
        width: 1em;
        height: 1em;
    }

    .container-action-file-explorer * {
        margin: 0 !important;
    }

    .file-explorer-header .search-input {
        grid-row: 3;
        grid-column: 1/3;
    }

    .feh-parent .display-choise-fe {
        grid-row: 2;
        grid-column: 1;
    }

    .feh-parent .search-input {
        grid-row: 2 !important;
        grid-column: 1/3 !important;
    }

    .icon-item .fe-icon svg {
        font-size: 32px !important;
    }

    .fe-table, .icons-view-grid {
        --table-max-height: 55vh;
    }
}

/* Tablettes (600px à 1024px) */
@media (min-width: 600px) and (max-width: 1024px) {
    .file-explorer {
        padding: 10px 0;
    }

    .file-explorer-header {
        gap: 15px;
    }

    .path-history {
        font-size: 13px;
    }

    .container-action-file-explorer *, .file-explorer .ant-table-cell{
        font-size: 1.1em;
    }

    .container-action-file-explorer .moveItemIcon {
        width: 1.1em;
        height: 1.1em;
    }

    .fe-table, .icons-view-grid {
        --table-max-height: 62vh;
    }
}

/* Grands écrans (1024px à 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
    .file-explorer-header {
        grid-template-columns: auto 1fr auto;
        gap: 15px;
    }

    .path-history {
        font-size: 14px;
    }

    .container-action-file-explorer *, .file-explorer .ant-table-cell{
        font-size: 1.2em;
    }

    .container-action-file-explorer .moveItemIcon {
        width: 1.2em;
        height: 1.2em;
    }

    .fe-table, .icons-view-grid {
        --table-max-height: 75vh;
    }
}

/* Très grands écrans (1440px et plus) */
@media (min-width: 1440px) {
    .file-explorer-header {
        padding: 20px;
    }

    .path-history {
        font-size: 15px;
    }

    .container-action-file-explorer *, .file-explorer .ant-table-cell{
        font-size: 1.3em;
    }

    .container-action-file-explorer .moveItemIcon {
        width: 1.3em;
        height: 1.3em;
    }

    .fe-table, .icons-view-grid {
        --table-max-height: 85vh;
    }
}
