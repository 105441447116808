.no-documents-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    background: linear-gradient(135deg, #f0f2f5, #ffffff);
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.no-documents-container .ant-empty {
    margin-bottom: 20px;
}

.no-documents-container .ant-btn-primary {
    margin-top: 16px;
    border-radius: 5px;
}

.no-documents-container .ant-empty-image {
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}
