.hours-worked-container {
    padding: 20px;
}

.hours-worked-container .hours-worked-card {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hours-worked-container .hours-worked-title {
    text-align: center;
}

.hours-worked-container .filter-container {
    margin-bottom: 10px;
}

.hours-worked-container .year-select, .month-select {
    width: 100%;
}

.hours-worked-container .loading-container {
    text-align: center;
    padding: 50px 0;
    height: auto !important;
}

.hours-worked-container .table-row-light {
    background-color: #ffffff;
}

.hours-worked-container .table-row-dark {
    background-color: #f5f5f5;
}

.hours-worked-container .hours-worked-table {
    margin-top: 10px;
    --table-max-height: 70vh;
    max-height: var(--table-max-height);
    overflow: auto;
}

/* Smartphones (max-width: 600px) */
@media (max-width: 600px) {
    .hours-worked-container .hours-worked-container {
        padding: 10px;
    }

    .hours-worked-container .hours-worked-title {
        font-size: 1.2em;
    }

    .hours-worked-container .hours-worked-table {
        --table-max-height: 50vh;
    }
}

/* Tablettes (600px à 1024px) */
@media (min-width: 600px) and (max-width: 1024px) {
    .hours-worked-container .hours-worked-container {
        padding: 10px;
    }

    .hours-worked-container .hours-worked-title {
        font-size: 1.3em;
    }

    .hours-worked-container .hours-worked-table {
        margin-top: 10px;
        max-height: 55vh;
    }
}

/* Grands écrans (1024px à 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
    .hours-worked-container .hours-worked-container {
        padding: 20px;
    }

    .hours-worked-container .hours-worked-title {
        font-size: 1.5em;
    }

    .hours-worked-container .hours-worked-table {
        --table-max-height: 65vh;
    }
}

/* Très grands écrans (1440px et plus) */
@media (min-width: 1440px) {
    .hours-worked-container .hours-worked-container {
        padding: 10px;
    }

    .hours-worked-container .hours-worked-title {
        font-size: 1.7em;
    }

    .hours-worked-container .hours-worked-table {
        --table-max-height: 70vh;
    }
}
