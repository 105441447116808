.iconSidebar{
    margin-right: 15px !important;
    color : #3e38b1 !important;
    font-size : 25px !important;
}

.iconSideBarBtn{
    color : #3e38b1 !important;
    font-size : 25px !important;
}

.ant-menu-submenu-title {
    margin-top: 20px !important;
}

.ant-menu-title-content{
    white-space: normal !important;
    line-height: 1.2 !important;
    margin-inline-start: 0 !important;
}


.ant-layout-sider-zero-width-trigger{
    top : 20px !important;
}

.ant-scroll-number{
    margin-right: 20px !important;
    margin-top: 20px !important;
}

.ant-layout-sider{
    z-index : 3;
}

/* Sidebar container */
.sidebar-container {
    display: flex;
    z-index: 10;
    transition: transform 0.3s ease-in-out;
}

.menu-footer {
    position: fixed;
    bottom: 50px;
    width: 100%;
    padding: 10px 0 0 0;
    background-color: #fff;
}

.sidebar-mobile{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
}

.menuButton {
    position: absolute;
    top: 15px;
    right: 0;
    width: 40px !important;
    height: 60px;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    background-color: #fff;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 20;
}

.sidebar-container:not(.hidden) .menuButton {
    right: 250px;
}

.notification .ant-menu-item {
    width: 99% !important;
    height: auto !important;
    border-bottom: 1px solid #ddd !important;
    border-radius: 0 !important;
    margin: 0 !important;
}

.notification .ant-menu-title-content {
    margin: 7px 0 !important;
}
