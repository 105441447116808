/* Supprime l'espace pour la barre de navigation */
.content{
  margin: 0 !important;
}

/* Conteneur principal de la page de connexion */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  overflow: hidden;
}

/* Conteneur avec fond blanc pour le logo, la description et le formulaire */
.login-wrapper {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Contenu d'en-tête avec le logo et la description */
.login-header {
  text-align: center;
}

.login-logo {
  max-width: 170px;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.login-description {
  font-size: 16px;
}

/* Styles pour le formulaire de connexion */
.login, .passwordReset{
  width: 98%;
  margin: auto;
}

.login-form {
  width: 100%;
}

.login-form-forgot {
  float: right;
}

.login-form-button, .forgot-password-email-button {
  width: 100%;
}

/* Message d'erreur */
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

/* Style pour la ligne de séparation */
.login-wrapper hr {
  border: none;
  border-top: 1px solid #555;
  width: 90%;
  margin: 20px auto;
}

/* Ajustements de la responsivité pour différents écrans */
@media screen and (max-width: 480px) {
  .login-logo {
    max-width: 90px;
  }

  .login-description {
    font-size: 14px;
  }

  .login-wrapper {
    padding: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .login-logo {
    max-width: 120px;
  }

  .login-description {
    font-size: 18px;
  }

  .login-wrapper {
    padding: 30px;
    max-width: 400px;
  }

  .error-message{
    font-size: 16px;
  }
}

@media screen and (min-width: 1920px) {
  .login, .passwordReset{
    width: 68%;
  }

  .login-description {
    font-size: 22px;
  }

  .login-wrapper {
    max-width: 700px;
  }

  .error-message{
    font-size: 18px;
  }
}
