/* Style des cards de contrat */
.contrat-card {
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    transition: transform 0.2s ease;
}

.contrat-card:hover {
    transform: scale(1.02);
}

.cover-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enfant-photo {
    width: 80px;
    border-radius: 50%;
    border: 1px solid #ddd;
    margin: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.status-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.1em;
    padding: 5px 10px;
}

.contratList .title {
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.info-section {
    margin: 0;
}

.info-section p {
    margin: 0;
    font-size: 0.9em;
    color: #555;
    line-height: 1.4;
}

.ant-card-actions {
    border-top: 1px solid #f0f0f0;
    background-color: #fafafa;
}

/* Styles responsives des cards */
@media (min-width: 1600px) {
    .enfant-photo {
        width: 90px;
        height: 90px;
    }
    .title {
        font-size: 1.4em;
    }
    .status-tag {
        font-size: 1em;
    }
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .enfant-photo {
        width: 80px;
        height: 80px;
    }
    .title {
        font-size: 1.3em;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .enfant-photo {
        width: 70px;
        height: 70px;
    }
    .title {
        font-size: 1.1em;
    }
    .status-tag {
        font-size: 0.85em;
    }
}

@media (max-width: 576px) {
    .contrat-card {
        margin: 5px;
        padding: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .enfant-photo {
        width: 60px;
        height: 60px;
    }
    .title {
        font-size: 1em;
    }
    .info-section p {
        font-size: 0.85em;
    }
    .status-tag {
        font-size: 0.8em;
        padding: 4px 6px;
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .contrat-card {
        margin: 8px;
        padding: 10px;
    }
    .title {
        font-size: 1em;
    }
    .enfant-photo {
        width: 70px;
        height: 70px;
    }
}
