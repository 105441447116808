@keyframes fadeInScale {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes borderPulse {
    0% {
        border-color: rgba(24, 144, 255, 0.3);
        box-shadow: 0 0 5px rgba(24, 144, 255, 0.3);
    }
    50% {
        border-color: rgba(24, 144, 255, 1);
        box-shadow: 0 0 15px rgba(24, 144, 255, 1);
    }
    100% {
        border-color: rgba(24, 144, 255, 0.3);
        box-shadow: 0 0 5px rgba(24, 144, 255, 0.3);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}


