.enfant-details h5 {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 16px;
}

.enfant-details p, .contrat-details p {
    margin-bottom: 8px;
    padding-left: 10px;
    font-size: 14px;
}

.listeAbsences li {
    margin-bottom: 8px;
}

.listeAbsences p {
    margin: 0;
    padding-left: 10px;
}

.asmat-details, .enfant-details {
    background: #f9f9f9;
    padding: 16px;
    border-radius: 8px;
}
