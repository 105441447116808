/* Style général de la page du planning */
.planningCol {
    margin: 0 auto;
    padding-top: 20px;
}

.planning-mobile-btn{
    display: flex;
    margin: 0 0 1vh 0;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    width: 100%;
}

/* Style pour le conteneur blanc entourant le planning */
.view-container-bgWhite {
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Style des boutons pour ajouter des événements */
.planningCol button {
    border-radius: 5px;
    text-align: center;
    transition: all 0.3s ease;
}

/* Ajout d’un effet survol */
.planningCol button:hover {
    opacity: 0.9;
}

/* Ajustement des boutons pour les petits écrans */
@media screen and (max-width: 767px) {
    .planningCol {
        padding-top: 10px !important;
    }

    .planning-mobile-btn button {
        font-size: 10px;
    }

    /* Réduction de la taille de la police des titres du calendrier */
    #fc-dom-1 {
        font-size: smaller !important;
    }

    /* Ajustement de la taille du conteneur blanc */
    .view-container-bgWhite {
        padding: 10px;
        margin-top: 10px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }
}

/* Style personnalisé de la barre de défilement pour Firefox */
div {
    scrollbar-width: thin;
}

/* Style de la barre de défilement pour Chrome, Safari et Edge */
div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

div::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

div::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 4px;
}

/* Styles spécifiques pour les écrans moyens (tablettes) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .planningCol {
        padding-top: 15px !important;
    }

    .view-container-bgWhite {
        padding: 15px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    }

    .planningCol button {
        font-size: 1rem;
    }
}

/* Styles pour les écrans larges (ordinateurs de bureau) */
@media screen and (min-width: 1025px) {
    .planningCol {
        padding-top: 20px !important;
    }

    .view-container-bgWhite {
        padding: 20px;
        margin-top: 20px;
    }

    .planningCol button {
        font-size: 1.1rem;
    }
}
