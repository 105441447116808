.impots-card-container {
    transition: all 0.3s ease-in-out;
}

.impots-card-container:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.impots-card.completed {
    border-left: 5px solid green;
}

.impots-card.pending {
    border-left: 5px solid red;
}

.impots-card .ant-card-title {
    font-weight: bold;
}

.impots-card .ant-card-actions > li {
    transition: color 0.2s ease-in-out;
}

.impots-card .ant-card-actions > li:hover {
    color: #1890ff !important;
}
