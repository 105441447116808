/* Styles généraux */
.card-welcome {
  display: flex;
  background-color: #f0f0f0;
  padding: 3vh;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.card-welcome .icon {
  margin: auto 15px auto 0;
  font-size: 3em;
}

.welcome-message {
  border-left: 3px solid #1890ff;
  padding-left: 1vh;
}

.date {
  font-size: 1.5em;
  font-style: italic;
  color: #999;
}

.card-welcome .time-icon {
  font-size: 24px;
  margin-right: 5px;
}

.card-welcome .role-icon {
  font-size: 24px;
  color: #1890ff;
  margin-right: 10px;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

.personalized-message {
  font-size: 1.1em;
  font-style: italic;
  color: #555;
  margin-top: 10px;
}

.animate-message, .animate-greeting {
  animation: fadeInOut 1s ease-in-out;
}

.last-login {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #555;
  margin-top: 5px;
  font-style: italic;
}

/* Icône à gauche pour la dernière connexion */
.last-login-icon {
  margin-right: 8px;
  color: #1890ff;
  font-size: 1.2em;
}

.date-icon {
  margin-right: 5px;
  font-size: 1em;
  color: #1890ff; /* Couleur douce pour éviter une surcharge visuelle */
}

/* Met en majuscule la date */
.date{
  text-transform: capitalize;
}

/* Responsivité pour smartphones en orientation portrait */
@media screen and (max-width: 480px) {
  .card-welcome {
    flex-direction: column;
    padding: 0;
    text-align: center;
  }

  .card-welcome .icon, .last-login-icon, .date-icon {
    display: none;
  }

  .time-icon, .role-icon {
    font-size: 20px;
  }

  .welcome-message {
    border-left: 0;
    padding-left: 0;
    margin-top: 1vh;
  }

  .title, .date, .last-login  {
    margin: 0 0 5px 0;
  }

  .date {
    font-size: 1em;
  }

  .personalized-message {
    font-size: 0.9em;
    color: #333;
  }

  .last-login {
    font-size: 0.8em;
    color: #777;
    justify-content: center;
    margin-top: 8px;
  }
}

/* Responsivité pour smartphones en orientation paysage */
@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .card-welcome {
    flex-direction: row;
    align-items: center;
    padding: 2vh;
  }

  .card-welcome .icon, .last-login-icon, .date-icon {
    display: none;
  }

  .time-icon, .role-icon {
    font-size: 18px;
  }

  .welcome-message {
    border-left: 0;
    padding-left: 0;
    margin-top: 1vh;
  }

  .date {
    font-size: 1.2em;
  }

  .last-login {
    font-size: 0.8em;
    color: #777;
    justify-content: center;
    margin-top: 8px;
  }

  .personalized-message {
    font-size: 1em;
    color: #444;
  }
}

/* Responsivité pour tablettes en orientation portrait */
@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .card-welcome {
    align-items: center;
    padding: 0;
  }

  .welcome-message {
    border-left: 2px solid #1890ff;
    padding-left: 15px;
    margin: 1vh 0;
  }

  .card-welcome .icon {
    font-size: 1.5em;
    margin: auto 10px;
  }

  .time-icon, .role-icon {
    font-size: 22px;
  }

  .date {
    font-size: 1.3em;
  }

  .last-login {
    font-size: 1em;
  }

  .personalized-message {
    font-size: 1.1em;
    color: #555;
  }

  .last-login-icon, .date-icon {
    font-size: 1.1em;
  }
}

/* Responsivité pour tablettes en orientation paysage */
@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .card-welcome {
    flex-direction: row;
    padding: 3vh 2vh;
    align-items: center;
  }

  .card-welcome .icon {
    font-size: 1.3em;
  }

  .date {
    font-size: 1.7em;
  }

  .last-login {
    font-size: 1em;
    color: #555;
  }

  .last-login-icon, .date-icon {
    font-size: 1.2em;
  }

  .personalized-message {
    font-size: 1.2em;
  }
}

/* Pour écrans larges comme les desktops et iMac */
@media screen and (min-width: 1024px) {
  .card-welcome {
    padding: 2vh;
  }

  .card-welcome .icon{
    font-size: 2.5em;
  }

  .date {
    font-size: 1.4em;
  }

  .last-login {
    font-size: 1em;
    color: #555;
  }

  .last-login-icon, .date-icon {
    font-size: 1.3em;
  }

  .personalized-message {
    font-size: 1.2em;
    font-weight: 500;
    color: #666;
  }
}

@media screen and (min-width: 1920px) {
  .card-welcome .icon{
    font-size: 3em;
  }

  .time-icon, .role-icon {
    font-size: 28px;
  }

  .date {
    font-size: 1.8em;
  }

  .last-login {
    font-size: 1.2em;
    color: #333;
  }

  .last-login-icon {
    font-size: 1.5em;
  }

  .personalized-message {
    font-size: 1.2em;
    font-weight: bold;
    color: #444;
  }
}
