/* Conteneur principal pour centrer le contenu */
.signIn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Pour assurer un centrage vertical */
  overflow: hidden;
  flex-direction: column;
}

/* Conteneur d'inscription avec fond blanc */
.signIn-wrapper {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%; /* S'assure que le conteneur s'adapte sur de petits écrans */
}

/* Style de l'alerte */
.alert-Asmat {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center; /* Centre le texte sur petits écrans */
}

/* En-tête avec logo et description */
.signIn-header {
  text-align: center;
  margin-bottom: 20px;
}

.signIn-logo {
  max-width: 120px;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.signIn-description .icon {
  margin: auto 15px auto 0;
  font-size: 5em;
}

.signIn-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 25px;
  line-height: 1.5; /* Améliore la lisibilité */

  display: flex;
}

.signIn-description div {
  border-left: 3px solid #1890ff;
  padding-left: 1vh;
  text-align: left;
}

/* Formulaire */
.signIn-form {
  width: 100%;
}

.password-strength{
  width: 58%;
  margin : auto 0 auto auto;
}

.checkboxNotif{
  text-align: center;
  margin-left: 85px;
}

/* Pour les petits appareils (jusqu'à 480px de large, typiquement les smartphones) */
@media screen and (max-width: 480px) {
  .alert-Asmat {
    font-size: 12px;
    padding: 8px;
    margin: 0 0 10px 0;
  }

  .signIn-wrapper {
    padding: 20px;
  }

  .signIn-description {
    font-size: 14px;
  }

  .signIn-logo {
    max-width: 80px; /* Réduit la taille du logo sur petits écrans */
  }
}

@media screen and (max-width: 575px) {
  .password-strength{
    width: 100%;
  }

  .checkboxNotif{
    margin-left: 0;
  }
}

/* Pour les appareils de taille moyenne (jusqu'à 1024px de large, typiquement les tablettes) */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  .signIn-wrapper {
    max-width: 600px;
    padding: 30px;
  }

  .signIn-description {
    font-size: 15px;
  }

  .alert-Asmat {
    font-size: 14px;
    padding: 10px;
  }
}

/* Pour les grands écrans de bureau (jusqu'à 1920px de large) */
@media screen and (min-width: 1025px) and (max-width: 1920px) {
  .signIn-wrapper {
    max-width: 700px;
    padding: 35px;
  }

  .signIn-description {
    font-size: 18px;
  }

  .signIn-logo {
    max-width: 130px;
  }
}

/* Pour les très grands écrans (au-delà de 1920px de large, comme les moniteurs 4K) */
@media screen and (min-width: 1921px) {
  .alert-Asmat {
    font-size: 18px;
    padding: 15px;
    margin: 0 0 30px 0;
  }

  .signIn-wrapper {
    max-width: 800px;
    padding: 40px;
  }

  .signIn-description {
    font-size: 20px;
  }

  .signIn-logo {
    max-width: 140px;
  }
}
