.card-content-scrollable {
  overflow-y: auto;
}

.card-content-scrollable .ant-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-content-scrollable .ant-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-content-scrollable .ant-card .ant-card-head-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333;
}

.card-content-scrollable .ant-card .enfant-photo {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  object-fit: cover;
}

/* Responsive layout */
@media (max-width: 480px) {
  .card-content-scrollable {
    max-height: 30vh;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .card-content-scrollable {
    max-height: 36vh;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .card-content-scrollable {
    max-height: 46vh;
  }
}

@media (min-width: 1367px) {
  .card-content-scrollable {
    max-height: 50vh;
  }
}
