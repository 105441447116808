/* Styling pour le formulaire */
.form-container {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Styling pour les onglets */
.ant-tabs {
  margin-bottom: 20px;
}

/* Styling pour les boutons */
.form-button {
  margin-top: 10px;
}

/* Styling pour les étiquettes */
.form-label {
  font-weight: bold;
}

.ant-select-item {
  font-size: 1.2em !important;
}

.custom-opt-group {
  font-size: 1.3em !important;
  font-weight: bold !important;
  padding: 10px 15px !important;
  margin: 0 0 5px 0 !important;
}

.actif{
  color: #389e0d;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
}

.en_preparation {
  color: #0958d9;
  background: #e6f4ff;
  border: 1px solid #91caff;
}

.termine {
  color: #cf1322;
  background: #fff1f0;
  border: 1px solid #ffa39e;
}

.statut_inconnu {
  color: #333333;
  background-color: #eaeaea;
  border: 1px solid #aeaeae;
}