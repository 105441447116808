/* Statistiques et bouton d'ajout de contrat */
.filterSearch {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.stats {
  background-color: white;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 4px 4px rgba(50, 50, 50, 0.3);
}

.buttonAdd {
  position: relative;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 8px;
  background-color: white;
  transition: all 0.3s;
  box-shadow: 4px 4px rgba(50, 50, 50, 0.3);
}

.buttonAdd:hover {
  background: #eee;
}

.buttonAdd .button__text {
  color: #333;
  font-weight: 500;
  font-size: 0.9rem;
}

.buttonAdd .button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50%;
}

.buttonAdd .svg {
  width: 16px;
  height: 16px;
}

/* Radio Group */
.filter {
  text-align: center;
}

.filter .ant-radio-button-wrapper {
  border-color: #ddd;
  color: #333;
  font-size: 0.9rem;
}

/* Liste des contrats */
.contratList {
  overflow-y: auto;
  height: 85vh;
  margin-top: 20px;
  border-top: 3px solid #ddd;
}

.emptyList {
  background-color: white;
  height: 30vh;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.contrat-page-container .ant-row {
  margin: 0 !important;
}

/* Responsive styles */
@media (max-width: 576px) {
  .filterSearch, .stats, .buttonAdd {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .buttonAdd {
    height: 40px;
  }

  .button__text{
    font-size: 0.8em !important;
  }

  .filter .ant-radio-button-wrapper {
    font-size: 0.7rem;
  }

  .filterSearch{
    gap: 7px 15px;
  }

  .ant-statistic-title {
    font-size: 0.7em !important;
  }

  .ant-statistic-content {
    font-size: 0.9rem !important;
  }

  .filter {
    margin: 0 !important;
  }

  .contratList {
    height: 65vh !important;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .buttonAdd {
    height: 70px;
  }

  .contratList {
    height: 75vh;
  }
}

@media (max-width: 1024px) and (orientation: portrait) {
  .buttonAdd {
    height: 70px;
  }

  .contratList {
    height: 70vh;
  }
}
