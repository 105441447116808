.inputNumberImpots .ant-input-number-handler-wrap{
    margin-right: -20px;
}

.tableauImpots{
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inputNumberImpots{
    margin-bottom: 10px;
    width: 280px;
}
.InputSalaire .ant-input-number-input{
    text-align: center !important;
}

.impots-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.impots-row {
    margin-bottom: 10px;
}

.impots-label {
    font-weight:600;
    font-size: 14px;
}

.impots-value {
    font-size: 14px;
    font-weight:600;
    color: #1890ff; /* Couleur bleue */
}

.montantDeclarer{
    font-weight:bolder;
    font-size: 18px;
}


.col-mois {
    background-color: #beddf8 !important; /* Bleu clair */
}

.col-salaire {
    background-color: #f29eff !important; /* Lavande */
}

.col-avantagesNature {
    background-color: #90ee90 !important; /* Saumon clair */
}

.col-plus8heures {
    background-color: #ff8b7e !important; /* Vert clair */
}

.col-moins8heures {
    background-color: #f1e572 !important; /* Or pâle */
}

.col-heureComplementaire {
    background-color: #97c1f9 !important; /* Navajo blanc */
}
.impots-row{
    overflow-x: auto !important;
}
