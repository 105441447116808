/* Conteneur principal de la barre de filtres */
.planning-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-radius: 8px;
    gap: 10px;
}

/* Style des cases à cocher de filtre */
.planning-filters .ant-checkbox-wrapper {
    font-weight: 500;
    color: #333;
}

.planning-filters .ant-checkbox-wrapper:hover {
    color: #1890ff; /* Couleur d'accentuation au survol */
}

.title-filtre{
    font-weight: bold;
    text-decoration-line: underline;
}

.planning-filters .ant-btn {
    font-size: 0.8em;
}

/* Légende */
.calendar-legend {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding: 10px;
    border-top: 1px solid #ddd;
    gap: 10px;
    flex-wrap: wrap;
}

.legend-item {
    padding: 5px 10px;
    border-radius: 5px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border: 1px solid #555;
}

.legend-maladie {
    background-color: #b47c34;
}

.legend-accepte {
    color: #28a745;
}

.legend-attente {
    color: #dd7800;
}

.legend-refuse {
    color: #dc3545;
}

.legend-absence {
    color: #333333;
}

.legend-holiday {
    background-color: #008000;
}


/* Douce transition sur les événements lors du survol */
.custom-event {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.custom-event:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Style général de la barre de titre du calendrier */
.fc-toolbar-title {
    font-size: 1.5rem !important;
}

.full-calendar-container {
    max-width: 90%;
    margin: 0 auto;
}

.fc-daygrid-body {
    overflow: hidden !important;
}

.fc-scrollgrid-sync-table,
.fc-timegrid-slot {
    cursor: pointer !important;
}

.fc-license-message {
    display: none;
}

.btn-group button, .fc-addExportButton-button, .fc-addRefreshButton-button {
    font-size: 14px;
}

/* Styles généraux des événements */
.custom-event {
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

/* Styles pour les événements refusés avec un motif diagonal */
.rejected-event .fc-event-main {
    font-weight: bold !important;
    position: relative;
    padding-left: 27px; /* Espace pour l'icône */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ff0000' fill-opacity='0.15'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 25px 25px;
    animation: pulseBackground 1.5s infinite; /* Pulsation légère */
}

/* Icône de croix pour signaler le refus */
.rejected-event .fc-event-main::before {
    content: '✖'; /* Icône de refus */
    position: absolute;
    left: 1px;
    top: 5px;
    font-size: 1.2em;
    color: #f44336; /* Rouge vif pour indiquer le refus */
    z-index: 2;
    background: #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

/* Styles pour les événements en attente avec un fond diagonale */
.pending-event .fc-event-main {
    font-weight: bold !important;
    position: relative;
    padding-left: 35px; /* Espace pour l'icône à gauche */
    background: linear-gradient(45deg, #000 1.25%, #ffffff00 1.25%, #ffffff00 48.75%, #000 48.75%, #000 51.25%, #ffffff00 51.25%, #ffffff00 98.75%, #000 98.75%);
    background-size: 25px 25px;
    background-position: 10px 10px;
    animation: pulseBackground 1.5s infinite; /* Animation de pulsation */
}

/* Icône pour l'état en attente */
.pending-event .fc-event-main::before {
    content: '⏳'; /* Icône de sablier */
    position: absolute;
    left: 1px; /* Position à gauche de l’événement */
    top: 5px;
    font-size: 1.2em;
    z-index: 2; /* Assurer que l'icône reste visible */
    background: #ee8700;
    border-radius: 10px;
    padding: 5px;
}

/* Styles pour les événements d'arrêt maladie */
.arret-maladie-event .fc-event-main {
    position: relative;
    padding: 0 0 0 35px;
}

/* Icône pour les arrêts maladie */
.arret-maladie-event .fc-event-main::before {
    content: '🩺'; /* Icône de trousse de premiers secours */
    position: absolute;
    left: 1px;
    top: 5px;
    font-size: 1.2em;
    z-index: 2; /* Assurer que l'icône reste visible */
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
}

/* Styles pour les événements d'absences prévues au contrat */
.absence-event .fc-event-main {
    position: relative;
    padding: 0 0 0 35px;
}

/* Icône pour les absences prévues au contrat */
.absence-event .fc-event-main::before {
    content: '🌴'; /* Icône de palmier */
    position: absolute;
    left: 1px;
    top: 5px;
    font-size: 1.2em;
    z-index: 2; /* Assurer que l'icône reste visible */
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;
}

/* Désactivation des animations de pulsation */
.no-animation .fc-event-main {
    animation: none !important;
}

/* Animation de pulsation douce */
@keyframes pulseBackground {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.4; }
}

/* Réduction de la taille du texte dans les créneaux horaires pour les petits écrans */
.fc-event-title-container {
    font-size: 0.9em;
}

.fc-event {
    padding: 2px 4px; /* Ajustement pour une meilleure visibilité */
}

/* Met en majuscule la première lettre du titre */
.fc-toolbar-title {
    text-transform: capitalize;
}

/* Met en majuscule la première lettre des jours de la semaine */
.fc-col-header-cell, .fc-list-day {
    text-transform: capitalize;
}

/* Flèche de l'indicateur avec l'heure */
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
    position: absolute;
    z-index: 4;
    left: 0;
    border-width: 10px 5px 5px 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0);
    border-radius: 0px 10px 10px 0px;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0);
}

/* Ligne de l'indicateur nowIndicator plus épaisse */
.fc .fc-timegrid-now-indicator-line {
    opacity: 0.8;
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    margin-top: -2px;
    border-style: solid;
    border-color: #ff6b6b;
    border-width: 5px 0 0;
}

/* Affichage de l'heure dans la flèche */
.fc .fc-timegrid-now-indicator-arrow::after {
    content: attr(data-time);
    position: absolute;
    top: -10px;
    left: 15px;
    font-size: 10px;
    color: white;
    font-weight: bold;
    background-color: #ff6b6b;
    padding: 2px 5px;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
}

/* Responsive pour les smartphones */
@media screen and (max-width: 576px) {
    .fc-timegrid-slot, .fc-timegrid-axis-cushion, .fc-col-header-cell-cushion {
        font-size: 0.7em;
    }

    .fc-toolbar-title {
        font-size: 0.6em !important;
    }

    /* Taille du texte réduite pour les événements sur mobile */
    .fc-event-title-container {
        font-size: 0.7em;
    }

    /* Défilement horizontal pour les vues trop larges */
    .fc-view-container {
        overflow-x: auto !important;
    }

    .fc-daygrid-body, .fc-timegrid-slot {
        min-width: 150px;
    }

    /* Marge réduite pour optimiser l'espace */
    .full-calendar-container {
        padding: 0 10px;
    }

    /* Taille des boutons pour les smartphones */
    .btn-group button, .fc-addExportButton-button, .fc-addRefreshButton-button {
        font-size: 8px;
        padding: 4px; /* Taille réduite pour une meilleure lisibilité */
    }

    .calendar-legend {
        gap: 5px;
    }

    .legend-item {
        text-align: center;
        font-size: 0.6em;
        padding: 3px 6px;
    }
}

/* Responsive pour les tablettes en mode portrait */
@media screen and (min-width: 577px) and (max-width: 768px) {
    .fc-toolbar-title {
        font-size: 1.2rem !important;
    }

    /* Ajustement de la taille du texte des événements */
    .fc-event-title-container {
        font-size: 0.85em;
    }

    /* Défilement horizontal si nécessaire */
    .fc-view-container {
        overflow-x: auto !important;
    }

    .fc-daygrid-body, .fc-timegrid-slot {
        min-width: 200px;
    }

    .full-calendar-container {
        max-width: 100%;
        padding: 0 15px;
    }

    /* Taille des boutons pour les tablettes en mode portrait */
    .btn-group button, .fc-addExportButton-button, .fc-addRefreshButton-button {
        font-size: 12px;
    }

    .legend-item {
        font-size: 0.9em;
        padding: 4px 9px;
    }
}

/* Responsive pour les tablettes en mode paysage et les petits ordinateurs portables */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .fc-toolbar-title {
        font-size: 1.4rem !important;
    }

    .fc-event-title-container {
        font-size: 0.95em;
    }

    .full-calendar-container {
        max-width: 95%;
        padding: 0 20px;
    }

    .fc-daygrid-body, .fc-timegrid-slot {
        min-width: 180px;
    }

    /* Taille des boutons pour les tablettes en mode paysage et petits ordinateurs */
    .btn-group button, .fc-addExportButton-button, .fc-addRefreshButton-button {
        font-size: 13px;
    }

    .legend-item {
        font-size: 1em;
        padding: 5px 10px;
    }
}

/* Responsive pour les ordinateurs de bureau */
@media screen and (min-width: 1025px) and (max-width: 1366px) {
    .fc-toolbar-title {
        font-size: 1.5rem !important;
    }

    .fc-event-title-container {
        font-size: 1em;
    }

    .full-calendar-container {
        max-width: 85%;
    }

    .fc-daygrid-body, .fc-timegrid-slot {
        min-width: 160px;
    }

    /* Taille des boutons pour les ordinateurs de bureau */
    .btn-group button, .fc-addExportButton-button, .fc-addRefreshButton-button{
        font-size: 12px;
    }
}

/* Responsive pour les grands écrans (ex: iMac 27 pouces) */
@media screen and (min-width: 1367px) {
    .fc-toolbar-title {
        font-size: 1.5rem !important;
    }

    .fc-event-title-container {
        font-size: 1.1em;
    }

    .full-calendar-container {
        max-width: 75%;
    }

    .fc-daygrid-body, .fc-timegrid-slot {
        min-width: 200px;
    }

    /* Taille des boutons pour les grands écrans */
    .btn-group button, .fc-addExportButton-button, .fc-addRefreshButton-button {
        font-size: 16px;
    }
}
