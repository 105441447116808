.DocumentsPage .ant-select-selector, .DocumentsPage .ant-select-selection-placeholder {
  background-color: #4d4d4d !important;
  color: #e9b800 !important;
  border: none !important;
}

.button__wrapper .ant-select-selection-item {
  font-weight: bold;
  font-size: 28px;
}

.buttonParent svg {
  width: 24px;
  height: 24px;
  font-weight: bold;
}

.btnDocumentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.pageTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.pageDescription {
  font-size: 16px;
  color: #888;
  text-align: center;
  margin : 0;
}

button:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
