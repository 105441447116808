/* Styles généraux pour la table */
.table-wrapper {
    overflow-x: auto;
    margin: 0 auto;
    padding: 16px;
}

.ant-table {
    word-wrap: break-word;
    white-space: normal;
}

/* Styling pour les colonnes */
.ant-table-cell {
    word-wrap: break-word;
    white-space: normal;
}

/* Gestion des couleurs alternées pour les lignes */
.even-row {
    background-color: #ffffff;
}

.odd-row {
    background-color: #f9f9f9;
}

.highlight {
    background: #00ca4e;
}

/* Responsivité pour smartphones (portrait) */
@media screen and (max-width: 480px) {
    .ant-table {
        font-size: 12px;
    }

    .ant-table-cell {
        padding: 8px;
    }

    .user-page .ant-modal {
        width: 90% !important;
        margin: 0 auto;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: 12px;
    }

    .user-page .ant-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .user-page .ant-modal-footer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .user-page .ant-modal-footer .user-page .ant-btn {
        margin-bottom: 8px;
    }
}

/* Responsivité pour petits écrans (paysage) */
@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
    .ant-table {
        font-size: 12px;
    }

    .ant-table-cell {
        padding: 10px;
    }

    .user-page .ant-modal {
        width: 85% !important;
        margin: 0 auto;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: 13px;
    }

    .user-page .ant-btn {
        font-size: 13px;
    }
}

/* Responsivité pour tablettes (portrait) */
@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    .ant-table {
        font-size: 14px;
    }

    .ant-table-cell {
        padding: 12px;
    }

    .user-page .ant-modal {
        width: 75% !important;
        margin: 0 auto;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: 14px;
    }

    .user-page .ant-btn {
        font-size: 14px;
    }
}

/* Responsivité pour tablettes (paysage) */
@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
    .ant-table {
        font-size: 15px;
    }

    .ant-table-cell {
        padding: 14px;
    }

    .user-page .ant-modal {
        width: 70% !important;
        margin: 0 auto;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: 15px;
    }

    .user-page .ant-btn {
        font-size: 15px;
    }
}

/* Responsivité pour laptops */
@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .ant-table {
        font-size: 16px;
    }

    .ant-table-cell {
        padding: 16px;
    }

    .user-page .ant-modal {
        width: 60% !important;
        margin: 0 auto;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: 16px;
    }

    .user-page .ant-btn {
        font-size: 16px;
    }
}

/* Responsivité pour grands écrans (iMac 27 pouces ou équivalents) */
@media screen and (min-width: 1441px) {
    .ant-table {
        font-size: 18px;
    }

    .ant-table-cell {
        padding: 20px;
    }

    .user-page .ant-modal {
        width: 50% !important;
        margin: 0 auto;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        font-size: 18px;
    }

    .user-page .ant-btn {
        font-size: 18px;
    }
}
