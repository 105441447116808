/* Styles de base pour la structure */
.dashboard {
    padding: 0 10px 0 0;
    max-height: 90vh;
    overflow: auto;
}

.even-row {
    background-color: #ffffff;
}
.odd-row {
    background-color: #f9f9f9;
}


/* Styles pour les petits écrans (téléphones) */
@media (max-width: 480px) {
    .dashboard {
        width: 100%;
        max-height: 82vh;
    }
}

/* Pour les téléphones en mode paysage ou petits écrans moyens */
@media (min-width: 481px) and (max-width: 767px) {
    .dashboard {
        width: 100%;
        max-height: 82vh;
    }
}

/* Pour les écrans de 1024px de largeur ou moins (téléphones et petites tablettes) */
@media (max-width: 1024px) {
    .dashboard {
        width: 100%;
        max-height: 82vh;
    }
}

/* Pour les tablettes en paysage et petits écrans d'ordinateur (de 1025px à 1366px) */
@media (min-width: 1025px) and (max-width: 1366px) {
    .dashboard {
        width: 100%;
        max-height: 90vh;
    }
}

/* Pour les grands écrans (résolutions HD et supérieures) */
@media (min-width: 1367px) {
    .dashboard {
        width: 100%;
        max-height: 90vh;
    }
}
