/* Styles généraux */
.quota-responsive-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bckgrd{
    background-color: #f0f0f0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Style pour le spinner */
.quota-circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

/* Style pour la barre de progression */
.quota-bar {
    width: 90%; /* Occupe toute la largeur disponible */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 3px 0;
}

.ant-tooltip-content{
    width: max-content !important;
}

/* Mobile (max-width: 600px) */
@media (max-width: 600px) {
    .quota-responsive-progress {
        padding: 5px;
    }
    .quota-circle span {
        font-size: 12px !important;
    }

    .quota-bar .ant-progress {
        margin-bottom: 4px;
    }

    .quota-bar .ant-progress-text {
        font-size: 12px;
    }

    .quota-bar > span {
        font-size: 12px;
    }
}

/* Tablettes (600px à 1024px) */
@media (min-width: 600px) and (max-width: 1024px) {
    .quota-circle span {
        font-size: 10px !important;
    }

    .quota-bar .ant-progress {
        margin-bottom: 6px;
    }

    .quota-bar > span {
        font-size: 14px;
    }
}

/* Grands écrans (1024px à 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
    .quota-circle {
        flex-direction: column;
        justify-content: center;
    }

    .quota-circle span {
        font-size: 10px !important;
    }

    .quota-bar .ant-progress {
        margin-bottom: 8px;
    }

    .quota-bar > span {
        font-size: 14px;
    }
}

/* Très grands écrans (1440px et plus) */
@media (min-width: 1440px) {
    .quota-circle span {
        font-size: 14px !important;
    }

    .quota-bar .ant-progress {
        margin-bottom: 10px;
    }

    .quota-bar > span {
        font-size: 16px;
    }
}
