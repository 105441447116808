/* Supprime l'espace pour la barre de navigation */
.content{
    margin: 0 !important;
}

/* Conteneur principal de la page de connexion */
.forgotpwd-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    overflow: hidden;
}

/* Conteneur avec fond blanc pour le logo, la description et le formulaire */
.forgotpwd-wrapper {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Contenu d'en-tête avec le logo et la description */
.forgotpwd-header {
    text-align: center;
}

.forgotpwd-logo {
    max-width: 170px;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.forgotpwd-description {
    font-size: 16px;
}

/* Styles pour le formulaire de connexion */
.forgotpwd, .passwordReset{
    width: 98%;
    margin: auto;
}

.forgotpwd-form {
    width: 100%;
}

.forgotpwd-form-login {
    float: right;
}

.forgotpwd-form-button {
    width: 100%;
}

/* Message d'erreur */
.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    text-align: center;
}

/* Style pour la ligne de séparation */
.forgotpwd-wrapper hr {
    border: none;
    border-top: 1px solid #555;
    width: 90%;
    margin: 20px auto;
}

.forgot-password-password-button {
    width: 100%;
    font-size: 16px;
    padding: 10px;
}

.forgotpwd-container .password-strength {
    width: 100% !important;
    margin: 0 auto !important;
}

/* Ajustements de la responsivité pour différents écrans */
@media screen and (max-width: 480px) {
    .forgotpwd-logo {
        max-width: 90px;
    }

    .forgotpwd-description {
        font-size: 14px;
    }

    .forgotpwd-wrapper {
        padding: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .forgotpwd-logo {
        max-width: 120px;
    }

    .forgotpwd-description {
        font-size: 18px;
    }

    .forgotpwd-wrapper {
        padding: 30px;
        max-width: 400px;
    }

    .error-message{
        font-size: 16px;
    }
}

@media screen and (min-width: 1920px) {

    .forgotpwd-description {
        font-size: 22px;
    }

    .forgotpwd-wrapper {
        max-width: 700px;
    }

    .error-message{
        font-size: 18px;
    }
}
