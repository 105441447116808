.terms-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
}

.terms-greeting {
    font-size: 16px;
    color: #333;
    margin : 10px 0 0 0 !important;
}

.terms-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.terms-logo {
    width: 120px;
    height: auto;
    margin-bottom: 10px;
}

.terms-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.terms-card {
    background: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.terms-section {
    margin-bottom: 20px;
}

.terms-section h2 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #0054ed;
}

.terms-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.terms-modal-section h2 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #0054ed;
}

.terms-modal-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
