/* Gestion couleur background */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body{
  min-height: 100vh;
  font-family: 'Merriweather', sans-serif;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 1000% 1000%;
  animation: gradient 30s ease infinite;
}

/* Styles globaux */
#root,
.ant-layout {
  background: transparent !important;
}

.ant-layout-header,
.ant-layout-content {
  background: transparent !important;
}

a{
  text-decoration: none !important;
}

.bodyContent{
  margin-left: 15px;
  margin-top: 15px;
}


/* Contenu sans header seulement si il est la  */
@media screen and (min-width: 1200px) {
  .bodyContent{
    margin-left: auto;
    width : 95%;
    margin-right: 15px;
  }
}

.icon {
  font-size: 1.7em;
  color: #1890ff;
}

.title{
  font-size: 1.3rem;
  color: #333;
}

/* Pour les container avec bg white*/
.view-container-bgWhite {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* Styles pour les écrans de petite taille (xs) */
@media screen and (max-width: 900px) {
  .file-explorer-active{
    margin: 0 auto !important;
  }
  .bodyContent{
    margin: 80px auto auto auto ;
    width : 98%;
  }
  .cpCard {
    min-height: 180px;
    min-width: 350px; /* Problème de largeur tableau sort du composant  */
  }
  .ant-table-cell{
    font-size: 0.8rem;
  }

  .title{
    font-size: 17px;
  }
}
