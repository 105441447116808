
#headerLink {
  font-size: 1.7rem;
  margin-bottom: 1vh;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #fff;
  z-index: 100;
}

.headerBtn {
  border: none;
  cursor: pointer;
  position: fixed;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  background: transparent;
  z-index: 500;
}

.open ul,
li {
  margin: 0;
  padding: 0;
}

.open ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
  z-index: 100;
}

.open li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  margin-right: 20px;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Modifiez la position du contenu de la page lorsque le header est ouvert */
nav.open~.page-content {
  transform: translateX(300px);
  /* Ajustez la valeur selon la largeur de votre header */
}

/* Ajoutez une transition pour un effet en douceur */
.page-content {
  transition: transform 0.3s;
}

.content{
  margin-left : 100px !important;
}

.disableUl{
  display : none !important;
}